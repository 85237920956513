@charset "utf-8";

/*
------------------------------------------------------------
Project Name : Local-Power Public;
------------------------------------------------------------
*/

/* Reset 
--------------------------------------------------------------------------------------------------------*/

/* 
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    @include vertical-align(baseline);
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* General 
--------------------------------------------------------------------------------------------------------*/
* {
    outline: none !important;
    box-sizing: border-box;

    &:after,
    &:before {
        box-sizing: border-box;
    }
}

html,
body {
    min-height: 100vh;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

body {
    -ms-overflow-style: scrollbar;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-size-adjust: none;
    font-family: 'Poppins', sans-serif;
    background-image: url('../assets/images/web-bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: $white;
    background-position: top;
    overflow-x: hidden;
    min-width: 32rem;

    @include bp("sm") {
        font-size: 1.4rem;
    }

    &.menu-open {
        overflow: hidden;

        .overlay-content {
            transform: none;
            overflow-y: auto;
            visibility: visible;
        }
    }

}

.overlay-content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 91;
    background-color: $darkBlue;
    @include transition(all 0.5s ease-in-out);
    width: 100%;
    transform: translateY(-120%);
    visibility: hidden;

    .close {
        display: block;
        text-align: right;
        padding: 2rem 2rem 0 0;
        position: absolute;
        right: 0;
        top: 0;

        @include bp('xs') {
            padding: 1.1rem 1.1rem 0 0;
        }

        img {
            @include bp('xs') {
                max-width: 2.7rem;
            }
        }
    }

    .menu-lg-font {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;

        @include bp('xs') {
            left: 0;
            top: 50%;
            transform: translate(-37%, -50%) rotate(90deg);
        }

        .MuiTypography-h1 {
            font-size: 35rem;
            font-weight: 700;
            line-height: 20rem;
            color: rgba(251, 251, 251, 0.03);

            @include bp('md') {
                font-size: 20rem;
            }

            @include bp('xs') {
                font-size: 15.7rem;
            }
        }
    }

    .menu-content {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        @include flex-direction(column);
        margin-top: 2rem;
        min-height: calc(100vh - 5rem);
        margin-bottom: 2rem;

        @include bp('xs') {
            min-height: auto;
            padding: 0 1.6rem;
        }


        .overlay-menu {
            width: 35rem;
            max-width: 100%;

            li {
                a {
                    font-size: 3.2rem;
                    color: $skyBlue2;
                    text-align: center;
                    display: block;
                    padding: 3.1rem 0 2.1rem;
                    position: relative;

                    @include bp('xs') {
                        font-size: 2.6rem;
                        padding: 2.2rem 2rem 1.2rem;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0.2rem;
                        left: 0;
                        bottom: 0;
                        background-color: $blueMain;
                        @include transition(all 0.5s);
                        filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.15));
                    }

                    &:hover:not(.active) {
                        color: $blueMain;

                        &:before {
                            width: 100%;
                        }
                    }

                    &.active {
                        color: $blueMain;
                        font-weight: 700;

                        &:before {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .login-block {
            text-align: center;
            margin-top: 5rem;

            @include bp('xs') {
                width: 100%;
            }

            .h3 {
                margin-bottom: 3.7rem;
                font-weight: 500;

                @include bp('xs') {
                    font-size: 2.2rem;
                    margin-bottom: 1.8rem;
                    line-height: 3rem;
                }
            }

            .MuiButton-containedPrimary {
                min-width: 20.5rem;

                @include bp('xs') {
                    min-width: 100%;
                }
            }
        }
    }
}

.wrapper {
    min-height: 100vh;
    overflow-x: hidden;
}

a {
    color: $skyColor;
    text-decoration: none;
    @include inline-block();
    @include transition(all 0.5s);

    &:hover {
        color: $blueMain;
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
    display: block;
}

h1 {}

h2 {}

h3 {}

h4 {}

h5 {}

h6 {}

p {
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

em {
    @include inline-block();
    font-style: normal;
}

input[type=text],
input[type=email],
input[type=password],
textarea {
    -webkit-appearance: none;
    appearance: none;
}

::-webkit-input-placeholder {
    color: $black;
}

::-moz-placeholder {
    color: $black;
}

:-ms-input-placeholder {
    color: $black;
}

:-moz-placeholder {
    color: $black;
}

::-moz-focus-inner {
    border: 0;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $black;
}

option:not(:checked) {
    color: $black;
}

.consumption-grid-box {
    padding: 1.1rem;
    background-color: $skyLightBlue;
    @include border-radius(0.6rem);

    @include bp('xs') {
        padding: 0.8rem 1.1rem;
    }

    .grid-header {
        margin-bottom: 1.7rem;
        @include flexbox();
        @include align-items(center);

        .gird-icon {
            line-height: 0;
            min-width: 1.6rem;
            margin-right: 0.3rem;
        }
    }
}

.text-center {
    text-align: center;
}

.main-content {
    padding-bottom: 12.4rem;

    @include bp('md') {
        padding-bottom: 8rem;
    }

    @include bp('xs') {
        padding-bottom: 4.5rem;
    }
}

.main-section {
    position: relative;
    padding-bottom: 1%;
    padding-top: 11.4rem;

    @include bp('xs') {
        padding-top: 8.3rem;
        padding-bottom: 0;
    }

    // .MuiContainer-root {
    //     @include bp('xs') {
    //         padding: 0;
    //     }
    // }

    .cs-content {
        padding: 6.2rem 0 8rem;
        text-align: center;

        @include bp('md') {
            padding: 4rem 0;
        }

        @include bp('md') {
            padding: 2.9rem 1.6rem;
        }
        
    }
}

.page-scrolled {
    .MuiAppBar-root {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        background-color: $white;
        padding: 1.9rem 0;

        @include bp('xs') {
            padding: 1.5rem;
        }
    }
}