.faq-content {
    p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        color: $silver;
        margin-bottom: 1.5rem;
        @include bp('xs') {
            font-size: 1.4rem;
            line-height: 2rem;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.faq-main-content {
    .main-section {
        .MuiContainer-root {
            @include bp('xs') {
                padding: 0 1.6rem;
            }
        }
    }
    .contact-us-section {
        padding: 13rem 0 2rem;
        @include bp('sm') {
            padding: 5rem 0 2rem;
        }

        &:after {
            content: '';
            position: absolute;
            width: 43rem;
            height: 45rem;
            bottom: 32rem;
            left: 1.8rem;
            background: linear-gradient(183.89deg, rgba(59, 130, 246, 0.06) 8.63%, rgba(59, 130, 246, 0) 95.32%);
            @include border-radius(4rem);
            transform: rotate(36.42deg);
            z-index: -1;

            @include bp('xs') {
                display: none;
            }
        }

        &:before {
            left: -8.7rem;
            top: -12.8rem;
        }
    }
}