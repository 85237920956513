@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

// common style link
@import "./colors";
@import "./mixins";
@import "./break-point";
@import "./form";
@import "./common";
@import "./card";
@import "./modal";
@import "./dev";

// header style
@import "./header/header";

// footer style
@import "./footer/footer";

// Pages CSS
@import "./pages/home";
@import "./pages/contact-us";
@import "./pages/about-us";
@import "./pages/battery-calculate";
@import "./pages/financing";
@import "./pages/materials";
@import "./pages/faq";