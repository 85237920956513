.c-dialog {
  .btn-close {
    position: absolute;
    top: 1.6rem;
    right: 1.3rem;
  }

  .status-round {
    width: 8rem;
    height: 8rem;
    margin: 0 auto;
    background-color: $greenLight;
    @include border-radius(50%);
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
  }

  .status-block {
    margin-top: 1.9rem;
    text-align: center;

    .text-success {
      font-size: 2.1rem;
      color: $green;
      line-height: 3.1rem;
      text-transform: capitalize;
    }

    .success-message {
      font-size: 1.6rem;
      color: $greenLightSecondary;
      line-height: 2rem;
      margin-top: 0.7rem;
      text-transform: capitalize;
    }
  }
}