.battery-main-content {
    .main-section {
        padding-bottom: 1rem;

        @include bp('xs') {
            padding-bottom: 4.3rem;
        }

        .cs-content {
            @include bp('md') {
                padding: 2.4rem 1.6rem;
            }
        }
    }

    .battery-content {
        width: 80%;
        max-width: 100%;
        margin: 6rem auto 0;
        position: relative;

        @include bp('sm') {
            margin: 4rem auto 0;
        }

        @include bp('xs') {
            width: 100%;
            margin: 1.7rem auto 0;
        }

        p {
            font-size: 2rem;
            line-height: 2.8rem;
            color: $silver;
            margin-bottom: 2.8rem;

            &:last-child {
                margin-bottom: 0;
            }

            @include bp('xs') {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }
    }

    .battery-calculate {
        padding: 10.2rem 0 8rem;
        position: relative;

        @include bp('md') {
            padding: 5rem 0;
        }

        @include bp('xs') {
            padding: 1rem 0;
        }

        &:after {
            content: '';
            position: absolute;
            width: 43rem;
            height: 43rem;
            bottom: 0;
            left: 11rem;
            background: linear-gradient(183.89deg, rgba(59, 130, 246, 0.06) 8.63%, rgba(59, 130, 246, 0) 95.32%);
            @include border-radius(4rem);
            transform: rotate(36.42deg);
            z-index: -1;

            @include bp('xs') {
                display: none;
            }
        }

        .h3 {
            text-align: center;
            line-height: 7.2rem;
            color: $purple2;
            margin-bottom: 4rem;

            @include bp('xs') {
                margin-bottom: 2.9rem;
            }
        }

        .battery-card {
            background-color: $white;
            box-shadow: 0 0.2rem 1.5rem rgba(10, 41, 69, 0.2);
            padding: 3rem 2.4rem;
            @include border-radius(2.5rem);

            @include bp('xs') {
                background-color: transparent;
                box-shadow: none;
                padding: 0;
                @include border-radius(0);
            }

            .battery-card-value {
                width: 49%;
                max-width: 100%;
                margin-bottom: 2.2rem;

                @include bp('xs') {
                    width: 100%;
                    margin-bottom: 0.9rem;
                }

                .MuiOutlinedInput-notchedOutline legend {
                    font-size: 1.06rem;
                }
            }

            .battery-power-card {
                background-color: $lightPink;
                @include border-radius(0.6rem);
                padding: 1.4rem;
                margin-bottom: 2.4rem;

                @include bp('xs') {
                    margin-bottom: 1rem;
                }

                &.mb-0 {
                    margin-bottom: 0;
                }

                .h5 {
                    font-size: 1.8rem;
                    margin-bottom: 1.4rem;

                    @include bp('sm') {
                        margin-bottom: 0.8rem;
                    }
                }

                .MuiTypography-h6 {
                    margin-bottom: 2.4rem;

                    @include bp('sm') {
                        margin-bottom: 1.5rem;
                    }
                }

                .main-row {
                    @include flexbox();
                    @include flex-wrap(wrap);
                    margin: 0 -2.4rem;

                    @include bp('sm') {
                        margin: 0;
                    }

                    .row-left,
                    .row-left {
                        width: 50%;
                        padding: 0 2.4rem;

                        @include bp('sm') {
                            width: 100%;
                            padding: 0;
                        }

                        .inner-row {
                            @include flexbox();
                            @include flex-wrap(wrap);
                            margin: 0 -0.8rem;

                            .row-field {
                                padding: 0 0.8rem 0.8rem;

                                @include bp('xs') {
                                    padding: 0 0.8rem 1.2rem;
                                }

                                &.field-big {
                                    width: 60%;
                                    max-width: 100%;

                                    // @include bp('xs') {
                                    //     width: 100%;
                                    // }
                                }

                                &.field-medium {
                                    width: 32%;
                                    max-width: 100%;

                                    @include bp('xs') {
                                        width: 60%;
                                    }
                                }

                                &.field-small {
                                    width: 40%;
                                    max-width: 100%;

                                    // @include bp('xs') {
                                    //     width: 40%;
                                    // }
                                }

                                .label-color-change {
                                    .MuiInputLabel-root {
                                        color: $silver2;
                                    }
                                }

                                @include placeholder {
                                    color: $silver2 !important;
                                    opacity: 1 !important;
                                    font-size: 1.4rem !important;
                                    font-weight: 400 !important;
                                    font-family: "Poppins", sans-serif;
                                }
                            }
                        }
                    }
                }

                .battery-action {
                    margin-top: 1.4rem;

                    @include bp('xs') {
                        margin-top: 0.2rem;
                    }

                    .MuiButtonBase-root {
                        min-height: 3.8rem;
                        width: 12.7rem;
                        min-width: auto;
                        font-weight: 400;
                        font-size: 1.4rem;
                        padding: 0;

                        @include bp('xs') {
                            width: 100%;
                        }
                    }
                }
            }

            .total-calculate {
                margin-top: 1.9rem;

                @include bp('xs') {
                    margin-top: 1rem;
                }
            }

            .estimate-box {
                margin-top: 3.3rem;
                @include flexbox();

                @include bp('sm') {
                    @include flex-wrap(wrap);
                    margin-top: 2.2rem;
                }

                .estimate-left {
                    @include bp('sm') {
                        width: 100%;
                    }
                }

                .estimate-left,
                .estimate-right {
                    @include flexbox();
                    @include align-items(center);

                    @include bp('xs') {
                        @include flex-wrap(wrap);
                        @include align-items(flex-start);
                    }

                    .estimate-text {
                        @include flexbox();
                        @include align-items(center);
                        font-size: 1.6rem;
                        color: $silver3;

                        @include bp('sm') {
                            min-width: 31.3rem;
                        }

                        @include bp('xs') {
                            min-width: 100%;
                        }

                        .MuiButtonBase-root {
                            padding: 0;
                            margin-left: 0.4rem;
                            min-width: auto;

                            &:hover {
                                background-color: transparent;
                                opacity: 0.7;
                            }
                        }
                    }

                    .estimate-value {
                        max-width: 100%;
                        width: 30%;
                        margin-left: 2.4rem;

                        @include bp('xs') {
                            width: 100%;
                            margin-left: 0;
                            margin-top: 0.6rem;
                        }
                    }
                }

                .estimate-right {
                    margin-left: -2rem;

                    @include bp('md') {
                        margin-left: 0;
                    }

                    @include bp('sm') {
                        margin-top: 2.4rem;
                        width: 100%;
                    }
                }
            }
        }
    }

    .contact-us-section {
        padding: 2rem 0;

        &:before {
            left: -8rem;
            top: -27rem;
            z-index: -1;
        }

        .MuiContainer-root {
            &:after {
                bottom: -8rem;
                right: -7.3rem;
            }
        }
    }

    .add-battery-section {
        padding-top: 1rem;
        .battery-box {
            .battery-info {
                .bi-inner {
                    @include bp('xs') {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .battery-specs-wrapper {
        padding: 7rem 0;
        text-align: center;

        @include bp('md') {
            padding: 4.5rem 0;
        }

        @include bp('xs') {
            padding: 3.5rem 0;
        }

        .title {
            margin-bottom: 40px;
            color: $purple2;

            @include bp('md') {
                margin-bottom: 30px;
            }
    
            @include bp('xs') {
                margin-bottom: 20px;
            }
        }

        .specs-card {
            padding: 20px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;

            .card-title {
                font-size: 28px;
                font-weight: 600;
                color: $purple;

                @include bp('sm') {
                    font-size: 26px;
                }
            }
    
            .inner-details {
                font-size: 28px;
                color: $purple;
                letter-spacing: 0.01em;
                line-height: 2.6rem;
                font-weight: 700;
                padding-top: 20px;

                @include bp('sm') {
                    font-weight: 500;
                    font-size: 26px;
                }
    
                span {
                    font-size: 20px;
                    font-weight: 400;

                    @include bp('sm') {
                        font-weight: 300;
                        font-size: 18px;
                    }

                    &.new-line {
                        display: block;
                        font-size: 18px;

                        @include bp('sm') {
                            font-size: 16px;
                        }
                    }
                }
    
            }

            .average-load-list {
                .MuiListItemIcon-root {
                    .MuiSvgIcon-root {
                        width: 4rem;
                        height: 4rem;

                        @include bp('sm') {
                            width: 3rem;
                            height: 3rem;
                        }
                    }
                }
                .load-name {
                    font-size: 20px;
                    color: $purple;
                    line-height: 2.6rem;
                    font-weight: 400;

                    @include bp('sm') {
                        font-weight: 300;
                    }
                }
                .load-power {
                    font-size: 22px;
                    color: $purple;
                    line-height: 2.6rem;
                    font-weight: 500;

                    @include bp('sm') {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
