.calculator-start-section {

    @include bp('xs') {
        .MuiCardContent-root {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .calculator-heading {
        padding: 3rem 0 3rem 0;

        @include bp('md') {
            padding: 2rem 0 2rem 0;
        }

        @include bp('sm') {
            padding: 1rem 1rem 1rem 1rem;
        }
    }

    .custom-width {
        @include bp('xxs') {
            width: 32.4rem;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    .cs-detail {
        font-size: 2.4rem;
        line-height: 3.9rem;
        color: $purple2;
        max-width: 100%;
        margin: 10px auto auto auto;

        @include bp('md') {
            font-size: 2rem;
            line-height: 2.4rem;
            margin: 10px auto auto auto;
        }

        @include bp('sm') {
            width: 100%;
            margin: 10px auto auto auto;
            font-size: 1.6rem;
            line-height: 2.2rem;
        }
    }

    .monthly-bill-label {
        font-size: 18px;
        padding-bottom: 5px;
        font-weight: 500;
    }

    .grid-custom-space {
        @include bp('xs') {
            margin-top: -1.6rem;
        }

        .MuiGrid-item {
            @include bp('xs') {
                padding-top: 1.6rem;
            }
        }
    }

    .form-label {
        @include flexbox();
        @include align-items(center);

        .MuiButtonBase-root {
            padding: 0;
            margin-left: 0.4rem;
            min-width: auto;

            &:hover {
                background-color: transparent;
                opacity: 0.7;
            }
        }
    }

    .annual-box-outer {
        margin: 3.6rem 0 2.2rem;

        @include bp('xs') {
            margin: 3.1rem 0 3rem;
            @include flexbox();
        }

        .MuiGrid-container {
            margin-top: -1.5rem;
        }

        // .MuiGrid-item {
        //     min-width: 25%;
        //     width: 25%;
        //     max-width: 25%;
        //     flex-basis: 25%;
        //     padding-top: 1.5rem;

        //     @include bp('md') {
        //         min-width: 33.33%;
        //         width: 33.33%;
        //         max-width: 33.33%;
        //         flex-basis: 33.33%;
        //     }

        //     @include bp('sm') {
        //         min-width: 33.33%;
        //         width: 33.33%;
        //         max-width: 33.33%;
        //         flex-basis: 33.33%;
        //     }
    }

    .advance-calculation {
        margin-bottom: 2.8rem;

        @include bp('xs') {
            margin-bottom: 1.9rem;
        }

        .MuiTypography-h5 {
            margin-bottom: 2.5rem;

            @include bp('xs') {
                margin-bottom: 1.3rem;
            }
        }

        .MuiGrid-container {
            @include bp('xs') {
                margin-top: -1.6rem;
            }

            .MuiGrid-item {
                @include bp('xs') {
                    padding-top: 1.6rem;
                }
            }
        }
    }

    .calculate-list {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        // margin: 1.8rem auto 0;
        margin: 1.8rem 0 0;

        @include bp('md') {
            // width: 100%;
            // @include justify-content(center);
        }

        @include bp('sm') {
            @include flex-wrap(wrap);
            @include justify-content(flex-start);
        }

        @include bp('xs') {
            @include justify-content(flex-start);
            // margin: 1.9rem auto 0;
            margin: 1.9rem 0 0;
        }

        li {
            position: relative;
            font-size: 1.4rem;
            color: $grey;
            padding-left: 1.1rem;
            margin-right: 1rem;
            line-height: 2rem;

            @include bp('sm') {
                margin-bottom: 1.7rem;
                padding-left: 2.1rem;
                margin-right: 0;

                // &:last-child {
                //     margin-bottom: 0;
                // }
            }

            &:last-child {
                margin-right: 0;
            }

            &:after {
                content: '';
                position: absolute;
                width: 0.4rem;
                height: 0.4rem;
                left: 0;
                top: 0.8rem;
                background-color: $grey;
                @include border-radius(2.5rem);

                @include bp('sm') {
                    left: 1rem;
                }
            }
        }
    }

    .link-btn {
        padding: 0;
        font-size: 18px;
        text-transform: none;
        background-color: transparent;
    }

    .btn-combo {
        text-align: center;
        .last-btn {
            margin-left: 15px;

            @include bp('xs') {
                margin-left: 0;
                margin-top: 15px;
            }
        }
    }
}

.solar-info-section {
    padding: 5rem 0;
    position: relative;

    @include bp('md') {
        padding: 2rem 0;
    }

    .MuiContainer-root {
        @include bp('xs') {
            padding: 0;
        }
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        @include border-radius(25.7rem);
        background: linear-gradient(274.11deg, rgba(59, 130, 246, 0.08) 2.4%, rgba(59, 130, 246, 0) 101.34%);

        @include bp('xs') {
            display: none;
        }
    }

    &:before {
        top: 20rem;
        right: -10.5rem;
        width: 45.7rem;
        height: 45.7rem;
    }

    &:after {
        top: 50%;
        width: 61.2rem;
        height: 61.2rem;
        right: -37.3rem;

        @include bp('md') {
            width: 41rem;
            height: 41rem;
            right: -27rem;
        }
    }

    .solar-info-box {
        background-color: $blueMain;
        padding: 3.1rem 2.4rem 5.5rem;
        @include border-radius(2.5rem);

        @include bp('xs') {
            @include border-radius(0);
            padding: 2.6rem 1.2rem 2.2rem;
        }

        .MuiTypography-h4 {
            text-align: center;
            margin-bottom: 4rem;
            color: white;

            @include bp('xs') {
                margin-bottom: 2.9rem;
            }
        }

        .solar-info-outer {
            .MuiGrid-item {
                min-width: 20%;
                width: 20%;
                max-width: 20%;
                flex-basis: 20%;

                @include bp('md') {
                    min-width: 25%;
                    width: 25%;
                    max-width: 25%;
                    flex-basis: 25%;
                }

                @include bp('sm') {
                    min-width: 33.33%;
                    width: 33.33%;
                    max-width: 33.33%;
                    flex-basis: 33.33%;
                }

                @include bp('xs') {
                    min-width: 50%;
                    width: 50%;
                    max-width: 50%;
                    flex-basis: 50%;
                }

                @include bp('xxs') {
                    min-width: 100%;
                    width: 100%;
                    max-width: 100%;
                    flex-basis: 100%;
                }
            }

            .MuiTypography-h3 {
                margin-bottom: 0.5rem;
                text-align: center;
            }

            .solar-message {
                font-size: 1.6rem;
                color: $white;
                @include flexbox();
                @include align-items(flex-start);
                line-height: 2rem;

                @include bp('xs') {
                    justify-content: center;
                }

                .MuiButtonBase-root {
                    padding: 0;
                    margin: 0.5rem 0.5rem 0 0;
                    min-width: auto;

                    &:hover {
                        background-color: transparent;
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

.pricing-details-section {
    padding: 5rem 0;

    @include bp('xs') {
        .MuiCardContent-root {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .pricing-block {
        display: flex;
        justify-content: center;
    }

    .main-list {
        .list-icons {
            min-width: auto;
            padding-right: 10px;
        }

        .MuiListItemText-primary {
            font-size: 18px;
        }
    }

    .price-block {
        @include bp('xs') {
            text-align: center;
        }
        span {
            font-size: 20px;
            display: inline;
        }
    }
}

.add-battery-section {
    padding-top: 5rem;

    @include bp('md') {
        padding: 2rem 0;
    }

    .MuiTypography-h4 {
        margin-bottom: 4rem;
        text-align: center;
        color: $purple2;

        @include bp('xs') {
            margin-bottom: 2.4rem;
        }
    }

    .battery-box {
        border: 0.3rem solid $skyBorder;
        background-color: $white;
        @include border-radius(2rem);
        padding: 4.6rem 0.9rem 2rem 0.9rem;
        // margin-bottom: 4rem;

        @include bp('xs') {
            padding: 0 1.2rem 1.7rem;
            // margin-bottom: 2.4rem;
        }

        .battery-note {
            margin-top: 3rem;
            font-size: 16px;
            color: $silver;
        }

        .battery-info {
            @include flexbox();
            @include flex-wrap(wrap);

            @include bp('md') {
                margin-bottom: -4rem;
            }

            @include bp('xs') {
                margin-bottom: -2rem;
            }

            .bi-inner {
                position: relative;
                padding: 0 3.3rem 0 1.3rem;
                width: 16.6%;
                text-align: center;

                @include bp('md') {
                    width: 33.33%;
                    margin-bottom: 4rem;

                    &:nth-child(3n) {
                        padding-right: 0;

                        &:after {
                            display: none;
                        }
                    }
                }

                @include bp('xs') {
                    width: 50%;
                    padding: 2.2rem 0 0;
                    margin-bottom: 2rem;

                    &:nth-child(3n) {
                        &:after {
                            display: block;
                        }
                    }

                    &:nth-child(2n) {
                        &:after {
                            display: none;
                        }
                    }

                    &:nth-child(4n) {
                        &:after {
                            display: none;
                        }
                    }
                }

                span {
                    font-size: 2rem;
                    color: $purple;
                    letter-spacing: 0.01em;
                    display: block;
                    line-height: 2.6rem;
                    padding-top: 0.5rem;
                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 0.1rem;
                    height: 100%;
                    right: 1.2rem;
                    top: calc(100% + -83px);
                    bottom: 0;
                    background-color: $lightSkyBlue;
                    min-height: 9.8rem;

                    @include bp('xs') {
                        top: 1rem;
                        right: 0;
                        min-height: auto;
                    }
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

.add-cost-section {
    padding: 10rem 0;
    position: relative;

    @include bp('md') {
        padding: 5rem 0;
    }

    @include bp('xs') {
        padding: 4.8rem 0 5rem;
    }

    .MuiContainer-root {
        @include bp('xs') {
            padding: 0;
        }
    }

    &:before {
        content: '';
        position: absolute;
        width: 43rem;
        height: 43rem;
        top: 73%;
        left: -4rem;
        background: linear-gradient(183.89deg, rgba(59, 130, 246, 0.06) 8.63%, rgba(59, 130, 246, 0) 95.32%);
        @include border-radius(4rem);
        transform: rotate(36.42deg);
        z-index: -1;

        @include bp('xs') {
            display: none;
        }
    }


    .cost-bg {
        @include border-radius(1.6rem);
        position: relative;
        padding: 8.1rem 0 9.1rem;
        background-image: url('../../assets/images/cost-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;

        @include bp('md') {
            padding: 5rem 0;
        }

        @include bp('xs') {
            padding: 2.5rem 1.5rem 3.2rem;
            @include border-radius(0);
            background-image: url('../../assets/images/cost-bg-mobile.jpg');
        }

        .h3 {
            text-align: center;
            margin-bottom: 6.1rem;

            @include bp('md') {
                margin-bottom: 3rem;
            }

            @include bp('xs') {
                margin-bottom: 2.1rem;
            }
        }

        .additional-box {
            max-width: 64%;
            width: 100%;
            margin: 0 auto;

            @include bp('md') {
                max-width: 75%;
            }

            @include bp('xs') {
                max-width: 100%;
            }

            .additional-box-detail {
                border: 0.1rem solid $white;
                @include border-radius(1.6rem);
                padding: 2.4rem 2.4rem 2.7rem;
                text-align: center;

                @include bp('sm') {
                    padding: 1.5rem;
                    height: 100%;
                }

                .h2 {
                    color: $white;
                }

                span {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(center);
                    font-size: 2rem;
                    color: $white;
                    letter-spacing: 0.01em;
                    margin-top: 0.5rem;

                    @include bp('sm') {
                        font-size: 1.4rem;
                        line-height: 2rem;
                    }

                    .MuiButtonBase-root {
                        padding: 0;
                        margin-left: 0.5rem;
                        min-width: auto;

                        &:hover {
                            background-color: transparent;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }
}

.contact-us-section {
    padding: 0 0 10rem;
    position: relative;

    @include bp('md') {
        padding: 0 0 5rem;
    }

    @include bp('xs') {
        padding: 0 0 4.3rem;
    }

    .MuiContainer-root {
        @include bp('xs') {
            padding: 0;
        }
    }

    &:before {
        content: '';
        position: absolute;
        left: -14.5rem;
        top: -10.9rem;
        width: 43rem;
        height: 43rem;
        background: linear-gradient(183.89deg, rgba(59, 130, 246, 0.06) 8.63%, rgba(255, 255, 255, 0) 95.32%);
        z-index: -1;
        transform: rotate(-61.18deg);
        @include border-radius(4rem);

        @include bp('xs') {
            display: none;
        }
    }

    .MuiContainer-root {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: -7.7rem;
            right: -9.3rem;
            width: 15.3rem;
            height: 17.3rem;
            background-image: url("../../assets/images/contact-add-bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;

            @include bp('xs') {
                display: none;
            }
        }
    }

    .contact-block {
        background-color: $skyBlue1;
        padding: 5.7rem 9.5rem 3.7rem;
        border-radius: 12.9rem 2rem 2rem 2rem;
        text-align: center;
        position: relative;
        overflow: hidden;

        @include bp('xs') {
            padding: 5.7rem 1.6rem 5.5rem;
            border-radius: 12.9rem 0 0 2rem;
        }

        .contact-bg-left,
        .contact-bg-right {
            position: absolute;
            opacity: 0.2;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .contact-bg-left {
            bottom: -2.7rem;
            left: 0;
            background-image: url("../../assets/images/contact-bg-left.png");
            width: 35rem;
            height: 31rem;

            @include bp('md') {
                width: 20rem;
                height: 20rem;
            }

            @include bp('xs') {
                transform: rotate(27deg);
            }
        }

        .contact-bg-right {
            top: -1rem;
            right: -5.1rem;
            background-image: url("../../assets/images/contact-bg-right.png");
            width: 23rem;
            height: 29rem;
            transform: rotate(-26deg);

            @include bp('md') {
                width: 20rem;
                height: 29rem;
            }

            @include bp('xs') {
                height: 20rem;
                transform: rotate(-15deg);
            }
        }

        .MuiTypography-h4 {
            margin-bottom: 1.2rem;
            color: $purple2;

            @include bp('xs') {
                margin-bottom: 2.2rem;
            }
        }

        p {
            font-size: 2.4rem;
            color: $silver;
            line-height: 3.9rem;
            margin-bottom: 4rem;

            &:last-child {
                margin-bottom: 0;
            }

            @include bp('md') {
                font-size: 2rem;
                line-height: 3rem;
            }

            @include bp('xs') {
                font-size: 1.6rem;
                line-height: 2.4rem;
                margin-bottom: 2.2rem;
            }
        }

        .button-block {
            margin-top: 3.5rem;

            @include bp('xs') {
                margin-top: 2.4rem;
            }
        }
    }
}

.finance-section {  
    text-align: center;

    @include bp('md') {
        // padding: 2rem 0 0;
    }

    .MuiTypography-h3 {
        &.h3 {
            color: $purple2;
        }
    }

    p {
        font-size: 2.4rem;
        line-height: 3.9rem;
        width: 87%;
        max-width: 100%;
        margin: 2.8rem auto;
        color: $silver;

        @include bp('md') {
            font-size: 2rem;
            line-height: 3rem;
        }

        @include bp('xs') {
            margin: 2.1rem 0;
            font-size: 1.6rem;
            line-height: 2.4rem;
            width: 100%;
        }
    }
}