.main-section {
  &.contact-section {
    .cs-content {
      padding: 6rem 0 0;

      @include bp('xs') {
        padding: 2.4rem 0 0;
      }

      .cs-detail {
        font-size: 2.4rem;
        line-height: 3.9rem;
        color: $silver;
        width: 65%;
        max-width: 100%;
        margin: 2.4rem auto 0;

        @include bp('sm') {
          width: 100%;
          margin: 1.5rem auto 0;
          font-size: 2rem;
          line-height: 3rem;
        }

        @include bp('xs') {
          width: 100%;
          font-size: 1.6rem;
          line-height: 2.2rem;
          margin: 1.8rem auto 0;
        }

        &.secondary-para {
          margin: 4.1rem auto 0;
          width: 80%;

          @include bp('sm') {
            width: 100%;
          }

          @include bp('xs') {
            margin: 2.1rem auto 0;
          }
        }
      }
    }

    @include bp('xs') {
      .MuiContainer-root {
        padding: 0 1.6rem;
      }
    }
  }
}

.contact-form {
  width: 79.2rem;
  max-width: 100%;
  margin: 5.5rem auto 0;

  @include bp('sm') {
    width: 100%;
    padding: 0 1.6rem;
  }

  @include bp('xs') {
    width: 100%;
    margin: 4.7rem auto 0;
    padding: 0 1.6rem;
  }

  .grid-mobile-hide {
    @include bp('xxs') {
      display: none;
    }
  }

  .MuiGrid-container {
    margin-top: -3.2rem;

    @include bp('xs') {
      margin-top: -2.4rem;
    }

    .MuiGrid-item {
      padding-top: 3.2rem;
      padding-left: 2.4rem;

      @include bp('xs') {
        padding-top: 2.4rem;
        padding-left: 1.2rem;
      }
    }
  }

  .MuiInputLabel-root {
    transform: translate(1.8rem, 1.8rem) scale(1);
    // &.MuiInputLabel-shrink {
    //   transform: translate(1.6rem, -0.6rem) scale(0.75);
    // }
  }

  .MuiInputBase-input {
    padding: 1.6rem 1.3rem;
  }

  .contact-action {
    padding: 2.6rem 0 0 2.4rem;
    display: flex;
    flex-direction: column;

    @include bp('xs') {
      padding: 1.4rem 0 0 1rem;
    }

    @include bp('xxs') {
      padding: 1.4rem 0 0 1.6rem;
      width: 100%;
    }

    .MuiButton-root {
      min-width: auto;
      width: 14rem;

      @include bp('xxs') {
        width: 100%;
        min-width: 100%;
      }
    }
  }
}