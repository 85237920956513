.footer {
    background-color: $darkBlue;
    padding: 5rem 1.5rem 3.3rem;
    @include bp('sm') {
        padding: 2.4rem 1.6rem;
    }

    .MuiContainer-root {
        @include bp('sm') {
            padding: 0;
        }
    }

    .footer-top {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.24);
        padding-bottom: 1.6rem;
        @include bp('sm') {
            @include flex-wrap(wrap);
            @include justify-content(center);
            padding: 2.8rem 0 0.6rem;
        }

        .ft-left {
            @include bp('xs') {
                max-width: 20.9rem;
            }
        }

        .ft-right {
            @include flexbox();
            @include bp('sm') {
                @include flex-wrap(wrap);
                @include justify-content(center);
                margin-top: 2.8rem;
                width: 100%;
            }

            li {
                margin-right: 3rem;
                @include bp('sm') {
                    text-align: center;
                    max-width: 100%;
                    margin: 0 1.5rem 2.6rem 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    font-size: 1.6rem;
                    color: $white;
                    @include bp('xs') {
                        font-size: 1.4rem;
                    }
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    .footer-bottom {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        padding-top: 2.4rem;
        @include bp('xs') {
            @include flex-wrap(wrap);
            @include justify-content(center);
        }

        .fb-right {
            @include bp('xs') {
                width: 100%;
                padding-top: 2.2rem;
            }
        }
        
        .fb-left, .fb-right {
            font-size: 1.3rem;
            line-height: 2rem;
            color: $white;
            @include bp('xs') {
                text-align: center;
                font-size: 1.2rem;
            }

            a {
                font-weight: 700;
                color: $white;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}