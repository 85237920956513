header {
    .MuiContainer-root {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        @include bp('xs') {
            padding: 0;
        }

        .logo {
            @include bp('xs') {
                max-width: 20.5rem;
            }
        }

        .menu-icon {
            min-width: 3.6rem;
            min-height: 2.5rem;
            position: relative;
            @include transform(rotate(0deg));
            @include transition (all 0.5s);
            cursor: pointer;
        
            span {
                display: block;
                position: absolute;
                height: 0.3rem;
                width: 100%;
                opacity: 1;
                background-color: $black;
                right: 0;
                @include transition (all 0.5s);
                @include border-radius(0.4rem);
                @include transform(rotate(0deg));
        
                &:first-child {
                    top: 0;
                    width: 2.6rem;
                }
        
                &:nth-child(2) {
                    top: 1.1rem;
                    width: 3.6rem;
                }
        
                &:nth-child(3) {
                    top: 2.3rem;
                    width: 2rem;
                }
            }
        }
    }
}