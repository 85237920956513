.materials-section {
    padding: 19rem 0 15.7rem;
    position: relative;

    @include bp('md') {
        padding: 7rem 0 9rem;
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
        @include border-radius(0 4rem 4rem 0);
        width: 42.5rem;
        height: 56.9rem;
        z-index: -1;
        background: linear-gradient(274.4deg, rgba(59, 130, 246, 0.1) 2.39%, rgba(59, 130, 246, 0) 100.85%);
        transform: matrix(-1, 0, 0, 1, 0, 0);

        @include bp('sm') {
            width: 42rem;
            height: 36.3rem;
        }

        @include bp('xs') {
            width: 23rem;
        }
    }

    &:before {
        bottom: 18.3rem;
        right: -10.9rem;
        height: 55.4rem;

        @include bp('sm') {
            bottom: auto;
            top: 13rem;
            right: -8.9rem;
            height: 38.4rem;
        }

        @include bp('xs') {
            right: -25.9rem;
        }
    }

    &:after {
        right: 0;
        top: 15.8rem;

        @include bp('sm') {
            top: 13.1rem;
        }
    }

    .grid-right {
        max-width: 51%;
        flex-basis: 51%;

        @include bp('sm') {
            max-width: 100%;
            flex-basis: 100%;
            order: 1;
        }
    }

    .grid-left {
        max-width: 49%;
        flex-basis: 49%;

        @include bp('sm') {
            max-width: 100%;
            flex-basis: 100%;
            order: 2;
        }
    }

    .materials-content-block {
        .solar-detail {
            @include bp('xs') {
                padding-top: 3.7rem;
            }

            .detail-header {
                text-align: right;

                @include bp('sm') {
                    text-align: center;
                }

                .MuiTypography-h6 {
                    color: $blueMain;
                    margin-top: 0.6rem;
                }
            }

            .detail-body {
                width: 30rem;
                margin: 4rem 0 4rem auto;
                max-width: 100%;
                @include flexbox();

                @include bp('sm') {
                    width: 33rem;
                    margin: 4rem auto;
                }

                .solar-info {
                    @include flexbox();
                    @include align-items(flex-start);

                    .solar-check {
                        width: 1.6rem;
                        height: 1.6rem;
                        min-width: 1.6rem;
                        @include flexbox();
                        @include justify-content(center);
                        @include align-items(center);
                        @include border-radius(2.5rem);
                        background-color: $skyBlue4;
                        margin-right: 0.6rem;
                    }

                    .info-name {
                        color: $blackSecondary;
                        font-size: 1.4rem;
                        margin-bottom: 0.4rem;
                    }
                }
            }

            .detail-footer {
                text-align: right;

                @include bp('sm') {
                    text-align: center;
                }

                @include bp('sm') {
                    padding: 0 1.6rem;
                }
            }
        }

        .solar-panel-box {
            text-align: right;
            display: block;

            @include bp('sm') {
                text-align: center;
            }

            img {
                @include bp('sm') {
                    max-width: 28.4rem;
                }
            }
        }
    }
}

.materials-content-reverse {
    padding: 19rem 0 15.7rem;
    // padding: 0 0 19rem;
    position: relative;

    @include bp('md') {
        // padding: 0 0 10rem;
        padding: 7rem 0 9rem;
    }


    @include bp('xs') {
        padding: 2rem 0 4.4rem;
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
        width: 65.3rem;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;

        @include bp('sm') {
            width: 42rem;
            height: 36.3rem;
        }

        @include bp('xs') {
            width: 23rem;
        }

        //     @include bp('md') {
        //         width: 46rem;
        //     }

        //     @include bp('xs') {
        //         width: 57rem;
        //     }
    }

    &:before {
        background-image: url('../../assets/images/materials-bg-right.png');
        top: 1.8rem;
        left: -11.1rem;
        height: 49.2rem;

        @include bp('md') {
            height: 40rem;
        }

        @include bp('xs') {
            top: 5.6rem;
            left: -23.1rem;
            height: 37rem;
        }
    }

    &:after {
        background-image: url('../../assets/images/materials-bg-left.png');
        left: 0;
        top: 0.1rem;
        height: 57.8rem;
        @include border-radius(4rem);
        overflow: visible;

        @include bp('md') {
            height: 49rem;
        }

        @include bp('xs') {
            top: 5.6rem;
            left: -23.1rem;
            height: 37rem;
        }
    }

    .materials-content-block {
        .solar-detail {
            width: 46rem;
            max-width: 100%;
            margin: 0 auto;

            @include bp('xs') {
                width: 100%;
                padding-top: 5.1rem;
            }

            .detail-header {
                text-align: left;

                @include bp('sm') {
                    text-align: center;
                }

                .MuiTypography-h6 {
                    color: $blueMain;
                    margin-top: 0.6rem;

                    @include bp('xs') {
                        margin-top: 1.4rem;
                    }
                }
            }

            .detail-body {
                width: 30rem;
                margin: 4rem auto 4rem 0;
                max-width: 100%;
                @include flexbox();

                @include bp('sm') {
                    width: 38rem;
                    margin: 4rem auto 4.2rem;
                    padding: 0 3.9rem;
                }

                .solar-info {
                    @include flexbox();
                    @include align-items(flex-start);

                    .solar-check {
                        width: 1.6rem;
                        height: 1.6rem;
                        min-width: 1.6rem;
                        @include flexbox();
                        @include justify-content(center);
                        @include align-items(center);
                        @include border-radius(2.5rem);
                        background-color: $skyBlue4;
                        margin-right: 0.6rem;
                    }

                    .info-name {
                        color: $blackSecondary;
                        font-size: 1.4rem;
                        margin-bottom: 0.4rem;
                    }
                }
            }

            .detail-footer {
                text-align: left;

                @include bp('sm') {
                    text-align: center;
                }

                @include bp('xs') {
                    padding: 0 1.6rem;
                }
            }
        }

        .solar-panel-box {
            text-align: center;
            display: block;

            img {
                @include bp('xs') {
                    max-width: 21rem;
                }
            }
        }
    }

    .grid-left {
        max-width: 40%;
        flex-basis: 40%;

        @include bp('sm') {
            max-width: 100%;
            flex-basis: 100%;
        }
    }

    .grid-right {
        max-width: 60%;
        flex-basis: 60%;

        @include bp('sm') {
            max-width: 100%;
            flex-basis: 100%;
        }
    }
}

.material {
    .cs-content {
        padding-bottom: 0;

        @include bp('md') {
            padding: 2.4rem 1.6rem 0;
        }
    }

    .contact-us-section {
        &:before {
            display: none;
        }
    }
}