.about-main-content {
    .cs-content {
        @include bp('md') {
            padding: 2.4rem 1.6rem;
        }
    }
    .about-content {
        width: 80%;
        max-width: 100%;
        margin: 6rem auto 0;
        position: relative;

        @include bp('sm') {
            margin: 4rem auto 0;
        }

        @include bp('xs') {
            width: 100%;
            margin: 1.7rem auto 0;
        }

        &:before {
            content: "";
            position: absolute;
            width: 43rem;
            height: 43rem;
            top: 54%;
            left: -27rem;
            background: linear-gradient(183.89deg, rgba(59, 130, 246, 0.06) 8.63%, rgba(59, 130, 246, 0) 95.32%);
            @include border-radius(4rem);
            transform: rotate(36.42deg);

            @include bp('xs') {
                display: none;
            }
        }
    
        p {
            font-size: 2rem;
            line-height: 2.8rem;
            color: $silver;
            margin-bottom: 2.8rem;
    
            &:last-child {
                margin-bottom: 0;
            }

            @include bp('xs') {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }
    }

    .contact-us-section {
        padding: 2rem 0;

        &:before {
            left: -9rem;
            top: -18rem;
            z-index: unset;
        }

        .MuiContainer-root{ 
            &:after {
                bottom: -6.6rem;
                right: -7.3rem;
            }
        }
    }
}