.button-combo-group {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);

    @include bp('xs') {
        @include flex-direction(column);
    }

    &.calculator-btn {
        @include bp('md'){
            display: block;
            text-align: center;

            .combo-left,
            .combo-right {
                margin-top: 20px;
            }
        }
    }

    .combo-left,
    .combo-right {
        @include bp('xs') {
            width: 100%;
        }

        .MuiButton-root {
            margin-right: 1.2rem;

            @include bp('xs') {
                margin-bottom: 1.2rem;
                margin-right: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.w-100 {
    @include bp('xs') {
        width: 100%;
    }
}