$white: #fff;
$black: #000;
$blackSecondary: #565656;
$silver: #647687;
$black3: #212529;
$skyLightBlue: #E9F4FF;
$grey: #919DA8;
$blueMain: #0170D6;
$skyColor: #D0E7F9;
$skyBorder: #CDE1FF;
$lightSkyBlue: #AFCAF2;
$purple: #193766;
$skyBlue1: #E8F1FE;
$darkBlue: #22334E;
$skyBlue2: #D8DEE3;
$skyBlue3: #c6e0f9;
$skyBlue4: #D6E8F9;
$purple2: #1C364F;
$lightPink: #F0F4FA;
$silver2: #81838A;
$silver3: #808080;
$greenLight: #EAFAF1;
$green: #219653;
$greenLightSecondary: #8BAD99;