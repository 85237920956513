.form-label {
    font-size: 1.4rem;
    color: $black3;
    line-height: 2.4rem;
    margin-bottom: 0.2rem;

    @include bp('sm') {
        font-size: 1.2rem;
    }
}

.MuiAccordionSummary-content {
    .MuiTypography-root {
        font-weight: 500;
        font-size: 2rem;
        line-height: 3.4rem;
        color: $purple2;
        @include bp('md') {
            font-size: 1.8rem;
            line-height: 2.6rem;
        }

        @include bp('xs') {
            font-size: 1.6rem;
            line-height: 2.2rem;
        }
    }
}

.accordion-custom-icon {
    .MuiAccordionSummary-expandIconWrapper {
        img {
            @include transition(all 0.5s);
            min-width: 2.4rem;
            &:last-child {
                margin-left: -2.4rem;
                opacity: 0;
            }
        }

        &.Mui-expanded {
            img {
                &:first-child {
                    opacity: 0;
                }

                &:last-child {
                    opacity: 1;
                }
            }
        }
    }
}